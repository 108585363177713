<template>
  <t-page>
    <template #header>
      <h3>{{ $t('fields.employees') }}</h3>
    </template>
    <t-grid>
      <t-grid-cell>
        <index-table :query="query" :fields="fields" :filters="filters" />
      </t-grid-cell>
    </t-grid>
  </t-page>
</template>

<script>
import Vue from 'vue';
import Employee from '~/models/Employee';
import IndexTable from '~/pages/shared/employees/partials/IndexTable';

export default Vue.extend({
  components: { IndexTable },

  data: () => ({
    filters: [],

    fields: ['fullname', 'email', 'meta.phonenumber', 'meta.position', 'created_at'],
  }),

  methods: {
    query() {
      return new Employee().where('organization_id', this.$route.params.id).include('user');
    },
  },
});
</script>
